import React, {useState} from 'react'
import {graphql, StaticQuery} from 'gatsby'
import {navigate} from 'gatsby-link'
// import Recaptcha from 'react-recaptcha'
import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import BlockContent from '../components/portableText'

import styles from '../components/page.module.css'

const Page = () => {
  // function encode (data) {
  //   return Object.keys(data)
  //     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
  //     .join('&')
  // }

  // State
  const [formValues, setFormValues] = useState([])
  // const [isVerified, setIsVerified] = useState(false)
  const [formErrors, setFormErrors] = useState(false)
  // const [recaptchaResponse, setRecaptchaResponse] = useState(null)

  // Google Recaptcha
  // let recaptchaLoaded = function () {
  //   console.log('Loaded')
  // }
  // let expiredCallback = function () {
  //   console.log('expired')
  //   setIsVerified(false)
  //   setRecaptchaResponse(null)
  // }
  // let verifyCallback = function (response) {
  //   console.log(response)
  //   if (response) {
  //     setIsVerified(true)
  //     setRecaptchaResponse(response)
  //   } else {
  //     setIsVerified(false)
  //     setRecaptchaResponse(null)
  //   }
  // }

  const handleChange = e => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }
  const handleDateChange = (date) => {
    // .toLocaleString()
    setFormValues({...formValues, dateTime: date, date: date.toLocaleString()})
  }

  // const handleSubmit = e => {

  // }

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   // console.log({e})

  //   if (isVerified && formValues.firstName && formValues.lastName && formValues.email && formValues.resume) {
  //     console.log('valid form')
  //     console.log({formValues})
  //     setFormErrors(false)

  //     const form = e.target
  //     fetch('/.netlify/functions/email', {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  //       body: encode({
  //         'to': 'wax@screaminpeach.com',
  //         'from': '@screaminpeach.com',
  //         'subject': 'ScreaminPeach.com - Careers',
  //         ...formValues,
  //         recaptcha: recaptchaResponse
  //       })
  //     })
  //       .then(() => navigate(form.getAttribute('action')))
  //       .catch(error => console.error(error))
  //   } else {
  //     console.log('invalid form')
  //     setFormErrors(true)
  //   }
  // }

  return (
    <StaticQuery
      query={graphql`
            query sanityCareersNewPage {
              sanityCareers {
                title
                subTitle
                _rawBody
                mainImage {
                  asset {
                    fluid(maxWidth: 1900) {
                      ...GatsbySanityImageFluid
                    }
                    fixed(width: 1900) {
                      ...GatsbySanityImageFixed
                    }
                  }
                }
                formSettings {
                  thankyou
                  enableForm
                }
                seoSettings {
                  title
                  description
                }
              }
            }
          `}
      render={data => (
        <>
          {data.sanityCareers.seoSettings && data.sanityCareers.seoSettings.title && data.sanityCareers.seoSettings.description && (<SEO title={data.sanityCareers.seoSettings.title} description={data.sanityCareers.seoSettings.description} />)}

          <Layout mainImage={data.sanityCareers.mainImage} title={data.sanityCareers.title}>
            <article className={styles.root} >

              <Container>
                <div className={styles.pageTitleWrapper}>
                  <h1 className={styles.pageTitle}>{data.sanityCareers.title}</h1>
                  <h4 className={styles.pagesubTitle}>{data.sanityCareers.subTitle}</h4>
                </div>
                <section className='section'>

                  <div className='container'>
                    <div className='content'>
                      <div className={styles.mainContent}>
                        {data.sanityCareers._rawBody && <BlockContent blocks={data.sanityCareers._rawBody} />}
                      </div>
                      {data.sanityCareers.formSettings.enableForm && (
                        <form
                          name='ScreaminPeach.com Careers'
                          method='post'
                          action={data.sanityCareers.formSettings.thankyou}
                          encType='multipart/form-data'
                          data-netlify='true' data-netlify-honeypot='bot-field'
                        >

                          <input type='hidden' name='form-name' value='contact' />
                          <div hidden>
                            <label>
                              Don’t fill this out:{' '}
                              <input name='bot-field' onChange={handleChange} />
                            </label>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'date'}>When Can You Start?</label>
                            <DatePicker
                              className={styles.customInput}
                              showTimeSelect
                              selected={formValues.dateTime}
                              onChange={handleDateChange}
                              onSelect={handleDateChange}
                              dateFormat='MMMM d, yyyy h:mm aa'
                              name='date'
                              required
                            />
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'firstName'}>
                              First Name
                            </label>
                            <div className='control'>
                              <input
                                className={styles.customInput}
                                type={'text'}
                                name={'firstName'}
                                // onChange={handleChange}
                                id={'firstName'}
                                required
                              />
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'lastName'}>
                              Last Name
                            </label>
                            <div className='control'>
                              <input
                                className={styles.customInput}
                                type={'text'}
                                name={'lastName'}
                                onChange={handleChange}
                                id={'lastName'}
                                required
                              />
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'phone'}>
                              Phone Number
                            </label>
                            <div className='control'>
                              <input
                                className={styles.customInput}
                                type={'text'}
                                name={'phone'}
                                // onChange={handleChange}
                                id={'phone'}
                              />
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'email'}>
                              Email
                            </label>
                            <div className='control'>
                              <input
                                className={styles.customInput}
                                type={'text'}
                                name={'email'}
                                onChange={handleChange}
                                id={'email'}
                                required
                              />
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'location'}>
                              Screamin Location
                            </label>
                            <div className='control'>
                              <select className={styles.customSelect}
                                name={'location'}
                                onChange={handleChange}
                                id={'location'} required>
                                <option value='' defaultValue>Choose a Location</option>
                                <option value='Old Town'>Old Town</option>
                                <option value='South Fort Collins'>South Fort Collins</option>
                                <option value='Longmont'>Longmont</option>
                                <option value='Johnstown'>Johnstown</option>
                              </select>
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'resume'}>
                              Resume
                            </label>
                            <div className='control'>
                              <textarea
                                className={styles.customTextarea}
                                name={'resume'}
                                onChange={handleChange}
                                id={'resume'}
                                required
                                rows='10'
                              />
                            </div>
                          </div>

                          <div className='field'>
                            <label className={styles.customLabel} htmlFor={'file'}>
                              Resume Upload
                            </label>
                            <div className='control'>
                              <input type='file' id='file' name='file' accept='.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf' />
                            </div>
                          </div>

                          <div>
                            <br />

                            {/* <Recaptcha
                            sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_PUBLIC}
                            render='explicit'
                            verifyCallback={verifyCallback}
                            onloadCallback={recaptchaLoaded}
                            expiredCallback={expiredCallback}
                          /> */}
                          </div>

                          <div className='field'>
                            <button className={styles.customButton} type='submit'>
                              Send
                            </button>
                          </div>

                          {/* {formErrors && (
                          <div className={styles.errors}><p>Unable to submit form. Please make sure all of your fields are filled out.</p></div>
                        )} */}

                        </form>
                      )}
                    </div>
                  </div>
                </section>
              </Container>
            </article>
          </Layout>
        </>
      )}
    />
  )
}
export default Page
